import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getCategories = async () => {
  const response = await axios.get(
    `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/product-categories?populate=image&sort=order:asc&filters[order][$gt]=0&pagination[limit]=15`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data?.data ?? [];
};

export const FetchCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: getCategories,
  });
};
