import { useState } from "react";
import Card from "@/components/common/card";
import SectionHeader from "@/components/common/section-header";
import Alert from "@/components/ui/alert";
// import { useQuery } from "@/framework/queries/graphql";
// import { QUERY_ENDPOINTS } from "@/framework/utils/query-endpoints";
import { sanitize } from "@/framework/utils/sanitize";
import Slick from "@/components/ui/carousel/slick";
import { FetchCategories } from "@/framework/api/fetch-categories";
const CategoryBlock = ({
  sectionHeading,
  roundedItemCount,
  slugPrefix = "",
}) => {
  const { data, isLoading: loading, isError, error } = FetchCategories();

  const [currentSlide, setCurrentSlide] = useState(0);
  const responsive = [
    {
      breakpoint: 1720,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 6,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 3,
      },
    },
  ];
  return (
    <div className="bg-gray-200 w-full h-auto flex flex-col py-12 px-2 md:px-8 lg:px-[3vw] mt-12 md:mt-0">
      {sectionHeading && (
        <div className="w-full flex items-center justify-center lg:px-[2vw]">
          <SectionHeader
            sectionHeading={sectionHeading}
            className="pb-0.5 mb-6 px-2"
            textClassName="text-center uppercase m-auto font-avenir px-8 bg-gray-200 z-[1]"
          />
        </div>
      )}
      {error ? (
        <Alert message={error?.message} />
      ) : (
        <Slick
          dots={false}
          navigation={true}
          infinite={true}
          slidesToScroll={1}
          fade={false}
          loop={true}
          currentSlide={currentSlide}
          onSlideChange={setCurrentSlide}
          responsive={responsive}
          autoplay={false}
          lazyLoad={true}
          dotsClass={"slick-dots slick-thumb product"}
          appendDots={(dots) => <ul>{dots}</ul>}
          customPaging={(i) => (
            <div
              className={`ft-slick__dots--custom_product ${
                currentSlide === i ? "active" : ""
              }`}
            ></div>
          )}
        >
          {loading
            ? Array.from({ length: roundedItemCount || 7 }).map((_, idx) => {
                return (
                  <Card
                    item={null}
                    href={`#`}
                    effectActive={true}
                    key={`card-rounded-${idx}`}
                  />
                );
              })
            : data?.map((category) => (
                <Card
                  key={`category--key-${category.id}`}
                  item={sanitize(category)}
                  href={`${slugPrefix}${sanitize(category).name}`}
                  effectActive={true}
                />
              ))}
        </Slick>
      )}
    </div>
  );
};

export default CategoryBlock;
